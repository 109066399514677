var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-news" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main-news__list" },
      _vm._l(_vm.news, function(message) {
        return _c(
          "div",
          { key: message.id, staticClass: "main-news__content" },
          [
            _c("div", { staticClass: "main-news__date" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.formatDate(message.date)) + "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "main-news__link main-news__link_news",
                attrs: { href: message.cleanUrl }
              },
              [_vm._v("\n        " + _vm._s(message.title) + "\n      ")]
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-news__title" }, [
      _c("a", { staticClass: "main-news__link", attrs: { href: "/news/" } }, [
        _vm._v("Новости ОЧКАРИК")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "main-news__link main-news__link_mobile",
          attrs: { href: "/news/" }
        },
        [_vm._v("Все новости")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }