<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('main-page', ['menu']),
  },
};
</script>

<template>
  <div class="catalog-menu" id="catalog-menu">
    <h3 class="catalog-menu__title">Весь каталог товаров</h3>
    <nav class="catalog-menu__wrapper">
      <a
        v-for="(item, i) of menu"
        :key="`category-${i}`"
        :class="['catalog-menu__link', `catalog-menu__link--${item.class}`]"
        :href="item.link"
      >
        <span class="catalog-menu__link-text">{{ item.name }}</span>
        <img v-if="item.image_main" :src="item.image_main" :alt="item.name" class="catalog-menu__link-img" />
      </a>
    </nav>
  </div>
</template>
