<script>
import 'swiper/css/swiper.css';
import { Swiper } from 'vue-awesome-swiper';
import { mapGetters } from 'vuex';
import ArrowLeft from 'src/apps/main-page/components/ProductHits/assets/arrow-left.inline.svg';
import ArrowRight from 'src/apps/main-page/components/ProductHits/assets/arrow-right.inline.svg';
import ProductCard from 'src/apps/main-page/components/ProductHits/ProductCard';
import { waitFor } from 'src/utils/wait-for';
import { idForAnalytics } from 'src/utils';
import { onProductClick } from 'src/utils/analytics';

export default {
  components: {
    ArrowLeft,
    ArrowRight,
    Swiper,
    ProductCard,
  },

  data() {
    return {
      currentIndex: 0,
      options: {
        speed: 500,
        spaceBetween: 10,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 3,
          checkInView: true,
        },
        preloadImages: false,
        centeredSlides: true,
        slidesPerView: 'auto',
        watchSlidesProgress: true,
        updateOnWindowResize: true,
        loop: true,
        navigation: {
          nextEl: '.product-hits__button--next',
          prevEl: '.product-hits__button--prev',
        },
        pagination: {
          el: '.product-hits__pagination',
          clickable: true,
          bulletClass: 'swiper-pagination__bullet',
          bulletActiveClass: 'swiper-pagination__bullet--active',
        },
        breakpoints: {
          1281: {
            slidesPerView: 4,
            spaceBetween: 21,
            slidesOffsetBefore: 0,
            centeredSlides: false,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters('main-page', ['items', 'clientInited']),
  },

  methods: {
    onClickProduct({ product, position }) {
      const payload = {
        product: {
          ...product,
          idForAnalytics: idForAnalytics(product.id, product.variant ? [product.variant] : []),
        },
        position,
      };
      onProductClick(payload);
      window.xcore.trigger('analyticsProductPromotionClick', payload);
    },

    handleResize() {
      this.$nextTick(() => {
        const index = this.$refs.swiper.$swiper.realIndex;
        if (window.innerWidth <= 1280 && index === 0) {
          this.$refs.swiper.$swiper.slideToLoop(1, 0);
        } else if (window.innerWidth > 1280 && index === 1) {
          this.$refs.swiper.$swiper.slideToLoop(0, 0);
        }
      });
    },

    triggerAnalytics() {
      const totalPrice = this.items.reduce((acc, v) => acc + v.price, 0);
      const lastItem = this.items[this.items.length - 1];

      const vk = {
        event: 'view_home',
        products: this.items.map(item => ({ id: item.id, price: item.price, recommended_ids: '18020,7418' })),
        total_price: totalPrice,
        currency_code: 'RUR',

        price_num: '',
        business_value: 500,
        category_ids: 19,
      };

      const gtag = this.items.map((item, i) => ({
        id: item.sku,
        name: item.name,
        list_name: 'hit_prodazh',
        brand: item.brandName,
        category: item.categoryName,
        list_position: i + 1,
        price: item.price,
        idForAnalytics: idForAnalytics(item.id, item.variant ? [item.variant] : []),
      }));

      const admitad = { categoryId: 'hits' };
      const ecommerce = { products: [] };

      // eslint-disable-next-line camelcase
      const my_target = { market_num: '', productId: lastItem.sku, price: lastItem.price, total_price: totalPrice };

      const payload = { admitad, ecommerce, my_target, gtag, vk };

      window.xcore.trigger('analyticsCatalog', payload);
      window.xcore.trigger('showProduct', payload);
      window.xcore.trigger('analyticsMainPage', payload);
    },
  },

  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    window.onloadAttach(async () => {
      await waitFor(() => this.clientInited, { timeout: 10000, tick: 200 });
      this.triggerAnalytics();
    });
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<template>
  <div class="product-hits">
    <div class="product-hits__title">
      <ArrowLeft class="product-hits__button product-hits__button--prev" />
      <span class="product-hits__title-text">Хиты продаж </span>
      <a class="product-hits__title-link showTapBarMenu" href="#">Все товары</a>
      <ArrowRight class="product-hits__button product-hits__button--next" />
    </div>

    <div class="product-hits__block">
      <Swiper ref="swiper" :options="options">
        <ProductCard
          v-for="(item, index) in items"
          :key="`hits${index + 1}`"
          :item="item"
          @clickProduct="onClickProduct"
        />
      </Swiper>

      <div class="product-hits__pagination swiper-pagination__bullets"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
