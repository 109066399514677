import { loadScript } from '../../utils';

export async function initMneniaPro() {
  loadScript('https://cdn.mneniya.pro/widgetscipts/ochkarikru/mp-widget.js');
}

export function updateProductRating() {
  if (window.mpLoadWidget) {
    window.mpLoadWidget();
  }
}
