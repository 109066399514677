<script>
import { mapGetters } from 'vuex';
import { getLocalAvailableGroups } from 'src/utils/categories';
import Banner from 'src/components/Banner';

export default {
  components: {
    Banner,
  },

  computed: {
    ...mapGetters('main-page', ['menu', 'mainMenuBanner']),
  },

  methods: {
    maybeRenderGroupName(name, idx) {
      if (idx === 0) {
        return name;
      }
    },

    isLenses(categoryName) {
      return categoryName === 'Контактные линзы';
    },

    getLocalAvailableGroups,
  },
};
</script>

<template>
  <div class="nav-catalog">
    <div
      v-for="(topCategory, i) in menu"
      :key="`topCategory-${i}`"
      :class="[
        'nav-catalog__item',
        {
          'nav-catalog__item--no-hover': !topCategory.children.length,
          [`nav-catalog__item--${topCategory.class}`]: topCategory.class,
        },
      ]"
    >
      <a class="nav-catalog__item-title" :href="topCategory.link">
        <span class="nav-catalog__item-title-text">{{ topCategory.name }}</span>
        <div class="nav-catalog__item-title-block">
          <img
            v-if="topCategory.image_main"
            :src="topCategory.image_main"
            :alt="topCategory.name"
            class="nav-catalog__item-title-img"
          />
        </div>
      </a>
      <div v-if="topCategory.children.length" class="nav-catalog__inner-list-wrapper">
        <a class="nav-catalog__inner-main-link" :href="topCategory.link">{{ topCategory.name }}</a>

        <div :class="[{ 'nav-catalog__inner-list-block': isLenses(topCategory.name) }]">
          <div class="nav-catalog__inner-list">
            <template v-for="{ name, children } in getLocalAvailableGroups(topCategory.children, topCategory.name)">
              <div
                v-for="(subChildren, ii) in children"
                :key="`subChildren-${name}-${ii}`"
                class="nav-catalog__inner-item"
              >
                <p class="nav-catalog__inner-item-title" v-if="maybeRenderGroupName(name, ii)">
                  {{ maybeRenderGroupName(name, ii) }}
                </p>
                <div class="nav-catalog__inner-item-links">
                  <a
                    v-for="category in subChildren"
                    :key="category.name"
                    class="nav-catalog__inner-item-link"
                    :href="category.link"
                    >{{ category.name }}</a
                  >
                </div>
                <a class="nav-catalog__inner-item-see-all" :href="topCategory.link">Смотреть все</a>
              </div>
            </template>
          </div>
          <template v-if="isLenses(topCategory.name)">
            <Banner class="nav-catalog__inner-banner" place="main-menu" :banner="mainMenuBanner" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
