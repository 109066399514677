export const mergeValues = (target = [], values = []) => {
  if (!target.length) {
    return values;
  }

  const probablyEditedValues = target.map(item => {
    const newData = values.find(data => item.id === data.id);

    if (newData) {
      return { ...item, ...newData };
    }

    return item;
  });

  const newValues = values.filter(data => !target.find(item => item.id === data.id));

  return [...probablyEditedValues, ...newValues];
};
