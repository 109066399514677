<script>
import Tooltip from 'src/components/Tooltip';
import ImageBanner from './ImageBanner';
import TextBanner from './TextBanner';

export default {
  name: 'Banner',

  components: {
    Tooltip,
    ImageBanner,
    TextBanner,
  },

  props: {
    place: {
      type: String,
      default: '',
    },
    banner: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      screen: 'desktop',
    };
  },

  methods: {
    handleResize() {
      if (window.innerWidth <= 480) {
        this.screen = 'mobile';
      } else if (window.innerWidth < 1280) {
        this.screen = 'tablet';
      } else {
        this.screen = 'desktop';
      }
    },
  },

  computed: {
    bannerImage() {
      if (this.bannerType === 'image') {
        if (this.banner.adaptive) {
          return this.banner.adaptive[this.screen] || this.banner.adaptive.desktop;
        }
        return this.banner.body;
      }
      return '';
    },
    bannerType() {
      return this.banner?.params?.type || '';
    },
  },

  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<template>
  <Tooltip :text="banner.hint" :disableOnMobile="true">
    <ImageBanner v-if="bannerType === 'image'" :banner="banner" :screen="screen" :bannerImage="bannerImage" />
    <TextBanner v-if="bannerType === 'text'" :banner="banner" />
  </Tooltip>
</template>
