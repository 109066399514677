var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "root", staticClass: "blog" },
    [
      _c(
        "a",
        {
          staticClass: "blog__title blog--text blog--text_title",
          attrs: { href: "/blog/" }
        },
        [_vm._v("Наш блог")]
      ),
      _vm._v(" "),
      _c(
        _vm.mainComponent,
        {
          tag: "component",
          staticClass: "blog__slider swiper-container",
          attrs: { options: _vm.swiperOptions }
        },
        [
          _vm._l(_vm.blogPosts, function(blogItem) {
            return _c(
              "figure",
              { key: blogItem.id, staticClass: "blog__item swiper-slide" },
              [
                _c(
                  "a",
                  {
                    staticClass: "blog__link",
                    attrs: { href: blogItem.cleanUrl }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "blog__image-wrapper" },
                      [
                        _c(_vm.computeImageComponent(_vm.imageUrl(blogItem)), {
                          tag: "component",
                          class: {
                            "blog__image-fallback": !_vm.imageUrl(blogItem),
                            lazy: true
                          },
                          attrs: {
                            src: _vm.imageUrl(blogItem),
                            width: "600",
                            height: "352"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("figcaption", { staticClass: "blog__content" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "blog__item-title blog--text blog__last-override_title"
                        },
                        [_vm._v(_vm._s(blogItem.title))]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "blog__item-label blog--text blog__last-override_subtitle"
                        },
                        [_vm._v(_vm._s(blogItem.category.title))]
                      ),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "blog__item-subtitle blog--text",
                        domProps: { innerHTML: _vm._s(blogItem.preview) }
                      })
                    ]),
                    _vm._v(" "),
                    _c("footer", { staticClass: "blog__meta" }, [
                      _c("div", { staticClass: "blog__meta-item blog--text" }, [
                        _c("i", { staticClass: "blog__icon" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(blogItem.views))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "blog__meta-item blog--text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.formatDate(blogItem.createdAt)) +
                            "\n          "
                        )
                      ])
                    ])
                  ]
                )
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "blog__item blog__item--last blog--text swiper-slide ",
              attrs: { href: "/blog/" }
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "blog__item-title blog__item-title--last blog--text blog__last-override_title"
                },
                [_vm._v("\n        Больше статей\n      ")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "blog__item-subtitle blog__item-text--last blog--text blog__last-override_subtitle"
                },
                [
                  _vm._v(
                    "\n        Ещё больше интересных статей и обзоров на современные тенденции и моду в оптике у нас в Блоге\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "blog__item-link blog-text blog__last-override_read-articles"
                },
                [_vm._v("Читать статьи")]
              )
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "blog__pagination" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }