var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-catalog" },
    _vm._l(_vm.menu, function(topCategory, i) {
      var _obj
      return _c(
        "div",
        {
          key: "topCategory-" + i,
          class: [
            "nav-catalog__item",
            ((_obj = {
              "nav-catalog__item--no-hover": !topCategory.children.length
            }),
            (_obj["nav-catalog__item--" + topCategory.class] =
              topCategory.class),
            _obj)
          ]
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-catalog__item-title",
              attrs: { href: topCategory.link }
            },
            [
              _c("span", { staticClass: "nav-catalog__item-title-text" }, [
                _vm._v(_vm._s(topCategory.name))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "nav-catalog__item-title-block" }, [
                topCategory.image_main
                  ? _c("img", {
                      staticClass: "nav-catalog__item-title-img",
                      attrs: {
                        src: topCategory.image_main,
                        alt: topCategory.name
                      }
                    })
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          topCategory.children.length
            ? _c("div", { staticClass: "nav-catalog__inner-list-wrapper" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-catalog__inner-main-link",
                    attrs: { href: topCategory.link }
                  },
                  [_vm._v(_vm._s(topCategory.name))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      {
                        "nav-catalog__inner-list-block": _vm.isLenses(
                          topCategory.name
                        )
                      }
                    ]
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "nav-catalog__inner-list" },
                      [
                        _vm._l(
                          _vm.getLocalAvailableGroups(
                            topCategory.children,
                            topCategory.name
                          ),
                          function(ref) {
                            var name = ref.name
                            var children = ref.children
                            return _vm._l(children, function(subChildren, ii) {
                              return _c(
                                "div",
                                {
                                  key: "subChildren-" + name + "-" + ii,
                                  staticClass: "nav-catalog__inner-item"
                                },
                                [
                                  _vm.maybeRenderGroupName(name, ii)
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "nav-catalog__inner-item-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.maybeRenderGroupName(
                                                  name,
                                                  ii
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "nav-catalog__inner-item-links"
                                    },
                                    _vm._l(subChildren, function(category) {
                                      return _c(
                                        "a",
                                        {
                                          key: category.name,
                                          staticClass:
                                            "nav-catalog__inner-item-link",
                                          attrs: { href: category.link }
                                        },
                                        [_vm._v(_vm._s(category.name))]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "nav-catalog__inner-item-see-all",
                                      attrs: { href: topCategory.link }
                                    },
                                    [_vm._v("Смотреть все")]
                                  )
                                ]
                              )
                            })
                          }
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.isLenses(topCategory.name)
                      ? [
                          _c("Banner", {
                            staticClass: "nav-catalog__inner-banner",
                            attrs: {
                              place: "main-menu",
                              banner: _vm.mainMenuBanner
                            }
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }