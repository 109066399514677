/**
  Сгруппировать элементы по группам + разбить элементы по 20 позиций максимум
 */
export function getAvailableGroups(children, opts = {}) {
  const groups = {};
  const { limit = 20 } = opts;

  // eslint-disable-next-line no-restricted-syntax
  for (const child of children) {
    const { group } = child;

    if (!groups[group]) {
      groups[group] = [[]];
    }

    const targetIndex = groups[group].length - 1;

    if (groups[group][targetIndex].length !== limit) {
      groups[group][targetIndex].push(child);
    } else {
      groups[group][targetIndex + 1] = [child];
    }
  }

  const result = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [k, v] of Object.entries(groups)) {
    result.push({ name: k, children: v });
  }

  return result;
}

export function getLocalAvailableGroups(children, category) {
  const result = getAvailableGroups(children, { limit: 12 });

  // Формирование данных для вывода меню солнцезащитных очков и оправ
  if (category === 'Солнцезащитные очки' || category === 'Оправы') {
    const isAllBrands = result.find(item => item.name === 'Все бренды' || item.name === 'Бренд');

    if (isAllBrands) {
      const allBrands = isAllBrands.children.flat();
      if (allBrands.length > 8) {
        const glassesSubcategories = result.map(item => {
          if (item.name === 'Все бренды' || item.name === 'Бренд') {
            // eslint-disable-next-line
            item.children = [allBrands.slice(0, 8), allBrands.slice(8, allBrands.length)];
          }
          return item;
        });
        return glassesSubcategories;
      }
    }
  }
  return result;
}
