<script>
import { mapGetters } from 'vuex';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { waitFor } from 'src/utils/wait-for';

export default {
  components: { Swiper, SwiperSlide },

  directives: {
    swiper: directive,
  },

  data() {
    return {
      tooltips: {},

      swiperOptions: {
        speed: 500,
        spaceBetween: 10,
        centeredSlides: true,
        preloadImages: false,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 2,
          checkInView: true,
        },
        autoHeight: true,
        autoplay: {
          delay: 3000,
        },
        slidesPerView: 'auto',
        loop: true,
        pagination: {
          el: '.main-slider__pagination',
          clickable: true,
          bulletClass: 'swiper-pagination__bullet',
          bulletActiveClass: 'swiper-pagination__bullet--active',
        },
        navigation: {
          nextEl: '.main-slider__button--next',
          prevEl: '.main-slider__button--prev',
        },
        breakpoints: {
          1440: {
            speed: 1000,
            spaceBetween: 0,
          },
        },
      },
      shownSlides: [],
    };
  },

  computed: {
    ...mapGetters('main-page', ['availablePromotions', 'clientInited']),

    swiper() {
      return this.$refs.swiper?.$swiper;
    },
  },

  methods: {
    onClick(item, index) {
      if (item.needUrl) {
        window.xcore.trigger('analyticsGalleryClick', { id: item.id, name: item.title, position: index });
        window.location.href = item.cleanUrl;
      }
    },

    onMouseOver() {
      this.swiper?.autoplay?.stop();
    },

    onMouseOut() {
      this.swiper?.autoplay?.start();
    },

    onToggleTooltip(item) {
      this.tooltips = {
        ...this.tooltips,
        [item.id]: !this.tooltips[item.id],
      };
    },

    isTooltipActive(item) {
      return !!this.tooltips[item.id];
    },

    triggerShowProductAnalytics(visibleItem, position) {
      const payload = {
        slider: [
          {
            id: visibleItem.id,
            name: visibleItem.title,
            position,
          },
        ],
      };
      window.xcore.trigger('analyticsBanner', payload);
    },

    handleSlideAnalytics() {
      if (this.swiper.visibleSlides.length === 0) {
        return;
      }
      const visibleId = +this.swiper.visibleSlides[0].attributes['data-id'].value;
      const position = +this.swiper.visibleSlides[0].attributes['data-position'].value;
      const visibleItem = this.availablePromotions.filter(item => {
        const shownSlide = this.shownSlides.some(slide => slide.id === item.id);
        return visibleId === item.id && !shownSlide;
      });
      if (visibleItem.length) {
        this.shownSlides = [...this.shownSlides, ...visibleItem];
        this.triggerShowProductAnalytics(...visibleItem, position);
      }
    },
  },

  mounted() {
    window.onloadAttach(async () => {
      await waitFor(() => this.clientInited, { timeout: 10000, tick: 200 });
    });
  },
};
</script>

<template>
  <Swiper
    v-if="clientInited"
    class="main-slider"
    ref="swiper"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
    :options="swiperOptions"
    @ready="handleSlideAnalytics"
    @transitionEnd="handleSlideAnalytics"
  >
    <SwiperSlide
      v-for="(item, i) in availablePromotions"
      :key="`main-slider-${item.id}`"
      class="main-slider__slide"
      :data-id="item.id"
      :data-position="i + 1"
    >
      <a
        class="main-slider__link"
        :is="item.needUrl ? 'a' : 'div'"
        :href="item.cleanUrl || ''"
        :data-name="item.title"
        :data-info="item.hint"
      >
        <div
          v-if="item.hint"
          @click.prevent="onToggleTooltip(item)"
          :class="['main-slider__hint', { 'main-slider__hint_active': isTooltipActive(item) }]"
        >
          <span class="main-slider__hint-button">
            ?
          </span>

          <div class="main-slider__hint-text">
            {{ item.hint }}
          </div>
        </div>

        <img
          v-if="item.images.default"
          class="main-slider__img swiper-lazy"
          @click.prevent="onClick(item, i + 1)"
          :alt="item.title"
          :data-srcset="
            `${item.images.default.src} ${item.images.default.width}w,
             ${item.images.medium.src} ${item.images.medium.width}w,
             ${item.images.small.src} ${item.images.small.width}w`
          "
          :data-src="item.images.default.src"
          sizes="(max-width: 1279px) calc(100vw - 46px), 929px"
        />
      </a>
    </SwiperSlide>

    <svg
      slot="button-prev"
      class="main-slider__button main-slider__button--prev"
      width="22"
      height="38"
      viewBox="0 0 22 38"
      preserveAspectRatio="xMinYMin meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 36L3 19L20 2" stroke="white" stroke-width="3" />
    </svg>

    <svg
      slot="button-next"
      class="main-slider__button main-slider__button--next"
      width="22"
      height="38"
      viewBox="0 0 22 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 2L19 19L2 36" stroke="white" stroke-width="3" />
    </svg>

    <div class="main-slider__pagination swiper-pagination__bullets" slot="pagination" />
  </Swiper>
  <div v-else class="main-slider__placeholder"></div>
</template>

<style lang="scss" src="./style.scss"></style>
