var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "nav-catalog__wrapper" },
        [_c("NavCatalog"), _vm._v(" "), _c("PromoSlider")],
        1
      ),
      _vm._v(" "),
      _c("Brands"),
      _vm._v(" "),
      _c("ProductHits"),
      _vm._v(" "),
      _c("MobileNavCatalog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }