import { loadScript } from '../../utils';

export async function initTalkMeChat() {
  if (!globalThis?.xliteConfig?.isAnalyticsEnabled) {
    return;
  }
  const id = '3c722de538651ae2e9a5e6b11d727874';
  try {
    loadScript(`https://lcab.talk-me.ru/support/support.js?h=${id}`);
  } catch (e) {
    console.error('talk me chat load error:', e);
  }
}
