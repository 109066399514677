<script>
import { adjustTooltipPosition } from 'src/utils';

export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      default: '',
    },
    disableOnMobile: {
      type: Boolean,
      default: false,
    },
    inline: Boolean,
  },
  methods: {
    mouseoverHandler(e) {
      adjustTooltipPosition(e, '.tooltip');
    },
  },
};
</script>

<template>
  <div :class="{ 'tooltip__wrapper--inline': inline }" class="tooltip__wrapper" @mouseover="mouseoverHandler">
    <slot></slot>
    <div v-if="text" :class="[{ 'tooltip--mobile-disable': disableOnMobile }, 'tooltip']">
      <div class="tooltip__text">{{ text }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
