import { contentAPI, productsAPI, menuAPI, bannerAPI } from 'src/api';
import { init } from 'src/apps/init';
import { mergeValues } from 'src/apps/main-page/utils/merge-values';

export default {
  namespaced: true,

  state: () => ({
    inited: false,
    clientInited: false,
    specialOffers: [],
    blogPosts: [],
    news: [],
    brands: [],
    promotions: [],
    menu: [],
    items: [],
    seo: [],
    mainMenuBanner: {},
  }),

  getters: {
    inited: state => state.inited,
    clientInited: state => state.clientInited,
    specialOffers: state => state.specialOffers,
    blogPosts: state => state.blogPosts,
    news: state => state.news,
    brands: state => state.brands,
    promotions: state => state.promotions,
    menu: state => state.menu,
    items: state => state.items,
    seo: state => state.seo,
    cityId: (_s, _g, _rs, rg) => rg['profile/fields']?.currentCityId,
    availablePromotions: state => state.promotions.filter(promotion => promotion.available),
    mainMenuBanner: state => state.mainMenuBanner,
  },

  actions: {
    async init({ dispatch, commit }) {
      await Promise.all([
        dispatch('fetchSpecialOffers'),
        dispatch('fetchBlogPosts'),
        dispatch('fetchNews'),
        dispatch('fetchBrands'),
        dispatch('fetchPromotions'),
        dispatch('fetchProductHits'),
        dispatch('fetchSEO'),
        dispatch('fetchMenu'),
        dispatch('fetchMainMenuBanner'),
      ]);

      commit('setInited', true);
    },

    async clientInit({ commit, dispatch }) {
      await init.wait();

      await Promise.all([
        dispatch('fetchBlogPosts', { select: ['id', 'views'] }),
        dispatch('fetchPromotions', { select: ['id', 'available'] }),
        dispatch('fetchProductHits', { select: ['id', 'price'] }),
      ]);

      commit('setClientInited', true);
    },

    async fetchSpecialOffers({ commit }) {
      try {
        const { data } = await contentAPI.specialOffers();

        commit('setSpecialOffers', data);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchMenu({ commit }) {
      try {
        const data = await menuAPI.getMenu({ type: 'catalog_sections_tree' });

        commit('setMenu', data);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBlogPosts({ commit, getters }, opts = {}) {
      try {
        const { select } = opts;
        const { cityId } = getters;

        const { data } = await contentAPI.getBlogPosts({ cityId, limit: 4, select });

        commit('setBlogPosts', data);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchNews({ commit }) {
      try {
        const { data } = await contentAPI.getNews({ limit: 6 });

        commit('setNews', data);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBrands({ commit }) {
      try {
        const { data } = await contentAPI.getBrands({ limit: 999 });

        commit('setBrands', data);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchPromotions({ commit, getters }, opts = {}) {
      try {
        const { select } = opts;
        const { cityId } = getters;

        const { data } = await contentAPI.getPromotions({ cityId, limit: 999, preset: 'slider', select });

        commit('setPromotions', data);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchProductHits({ commit }, opts = {}) {
      try {
        const { select } = opts;

        const value = await productsAPI.getProductHits({ preset: 'main', select });

        commit('setItems', value);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchSEO({ commit, getters }) {
      const { cityId } = getters;

      try {
        const result = await contentAPI.getDetails({ cityId, cleanUrl: '/' });

        commit('setSEO', result);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchMainMenuBanner({ commit }) {
      try {
        const payload = {
          place: 'main-menu',
          params: {
            options: {
              adaptive: true,
            },
          },
        };
        const result = await bannerAPI.getBanner(payload);

        commit('setMainMenuBanner', result || {});
      } catch (e) {
        console.error(e);
      }
    },
  },

  mutations: {
    setSpecialOffers(state, values) {
      state.specialOffers = mergeValues(state.specialOffers, values);
    },

    setBlogPosts(state, values) {
      state.blogPosts = mergeValues(state.blogPosts, values);
    },

    setNews(state, values) {
      state.news = mergeValues(state.news, values);
    },

    setBrands(state, values) {
      state.brands = mergeValues(state.brands, values);
    },

    setPromotions(state, values) {
      state.promotions = mergeValues(state.promotions, values);
    },

    setMenu(state, values) {
      state.menu = values;
    },

    setItems(state, values) {
      state.items = mergeValues(state.items, values);
    },

    setSEO(state, values) {
      state.seo = values;
    },

    setInited(state, value) {
      state.inited = value;
    },

    setClientInited(state, value) {
      state.clientInited = value;
    },

    setMainMenuBanner(state, value) {
      state.mainMenuBanner = value;
    },
  },
};
