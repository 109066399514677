<script>
import { mapGetters } from 'vuex';
import LazyLoad from 'vanilla-lazyload';

export default {
  data() {
    return {
      hideAfter: 6,
      preview: true,
      lazyLoad: null,
    };
  },

  computed: {
    ...mapGetters('main-page', ['specialOffers']),

    href() {
      return '/special-offers/';
    },
  },

  methods: {
    onToggle() {
      this.preview = !this.preview;
      this.$nextTick(() => this.lazyLoad?.update());
    },

    resizeHandler() {
      const value = window.innerWidth;

      if (value <= 640) {
        this.hideAfter = 5;
      } else if (value < 1023) {
        this.hideAfter = 4;
      } else {
        this.hideAfter = 6;
      }

      this.$nextTick(() => this.lazyLoad?.update());
    },

    canShowElement(idx) {
      return !this.preview || idx < this.hideAfter;
    },
  },

  mounted() {
    this.lazyLoad = new LazyLoad({ container: this.$refs.root, threshold: 0 });
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler);
  },

  beforeDestroy() {
    this.lazyLoad?.destroy();
  },
};
</script>

<template>
  <div ref="root" class="advantages__section">
    <div class="advantages">
      <a class="advantages__title" :href="href">Наши преимущества</a>

      <div class="advantages__wrapper">
        <template v-for="(item, i) in specialOffers">
          <a v-if="canShowElement(i)" :key="item.id" class="advantages__item" :href="item.cleanURL">
            <img
              v-if="item.image"
              class="advantages__img lazy"
              :data-src="item.image.src"
              :width="item.image.width"
              :height="item.image.height"
              :alt="item.image.alt"
            />

            <div class="advantages__text-wrapper">
              <span class="advantages__text">{{ item.title }}</span>
              <span class="advantages__text advantages__text--small"></span>
            </div>
          </a>
        </template>
      </div>

      <button @click="onToggle" class="advantages__btn">
        <template v-if="preview">Мало преимуществ? Здесь ещё больше ▼</template>
        <template v-else>Скрыть преимущества ▲</template>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
