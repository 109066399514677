var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Tooltip",
    { attrs: { text: _vm.banner.hint, disableOnMobile: true } },
    [
      _vm.bannerType === "image"
        ? _c("ImageBanner", {
            attrs: {
              banner: _vm.banner,
              screen: _vm.screen,
              bannerImage: _vm.bannerImage
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.bannerType === "text"
        ? _c("TextBanner", { attrs: { banner: _vm.banner } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }