<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      preview: true,
    };
  },

  computed: {
    ...mapGetters('main-page', ['seo']),

    h1() {
      return this.seo.h1;
    },

    body() {
      return this.seo.body;
    },

    previewText() {
      if (this.preview) {
        return 'Читать текст полностью';
      }

      return 'Скрыть текст';
    },
  },

  methods: {
    onClickPreviewText(ev) {
      ev.preventDefault();

      document.querySelector('.seo-article__content__text')?.classList.toggle('seo-article__content__text-columns');
      this.preview = !this.preview;

      setTimeout(() => {
        document.querySelector('.seo-article__content__text')?.classList.toggle('seo-article__content__text-columns');
      }, 0);
    },
  },
};
</script>

<template>
  <div class="seo-section">
    <article class="seo-article">
      <header class="seo-article__title">
        <h1>{{ h1 }}</h1>
      </header>

      <div :class="['seo-article__content', { all: !preview }]">
        <div
          v-for="(row, i) in body"
          :key="i"
          :class="['seo-article__content__text', 'seo-article__content__text-columns']"
          v-html="row"
        ></div>
      </div>

      <div class="seo-article__more">
        <a @click="onClickPreviewText" id="showall-seo" class="seo-article__more__button">
          {{ previewText }}
        </a>
      </div>
    </article>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
