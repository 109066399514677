<script>
export default {
  name: 'TextBanner',
  props: {
    banner: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<template>
  <a v-if="banner.body && banner.cleanUrl" :href="banner.cleanUrl" class="banner-text">
    <div class="banner-text__title">
      {{ banner.body }}
      <img
        v-if="banner.params.doShowArrow"
        :src="'/frontend-new/assets/icons/banner-arrow.svg'"
        class="banner-text__title--arrow"
        :alt="banner.title"
      />
    </div>
  </a>
  <div v-else-if="banner.body && !banner.cleanUrl" class="banner-text">
    <div class="banner-text__title">
      {{ banner.body }}
      <img
        v-if="banner.params.doShowArrow"
        :src="'/frontend-new/assets/icons/banner-arrow.svg'"
        class="banner-text__title--arrow"
        :alt="banner.title"
      />
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss" scoped></style>
