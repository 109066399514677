<script>
import Blog from './Blog';
import News from './News';

export default {
  components: {
    Blog,
    News,
  },
};
</script>

<template>
  <div class="blog-news">
    <Blog />
    <News />
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
