<script>
import { mapGetters } from 'vuex';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import LazyLoad from 'vanilla-lazyload';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  directives: {
    swiper: directive,
  },

  data() {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        loop: true,
        freeMode: true,
        breakpoints: {
          1280: {
            autoplay: {
              enabled: true,
              delay: 500,
            },
            speed: 1000,
          },
        },
      },

      lazyLoad: null,
    };
  },

  computed: {
    ...mapGetters('main-page', ['brands']),
  },

  mounted() {
    this.lazyLoad = new LazyLoad({ container: this.$refs.root });
  },

  beforeDestroy() {
    this.lazyLoad?.destroy();
  },
};
</script>

<template>
  <div ref="root" class="main-brands">
    <div class="main-brands__title">
      <h2 class="main-brands__title-text">Бренды</h2>
      <a class="main-brands__link" href="/brands">Все бренды</a>
    </div>
    <Swiper :options="swiperOptions" class="main-brands__slider">
      <SwiperSlide v-for="(item, i) in brands" :key="i" class="main-brands__brand">
        <a :href="item.url">
          <img
            v-if="item.imageObject"
            class="main-brands__brand-img lazy"
            :data-src="item.imageObject.src"
            :width="item.imageObject.width"
            :height="item.imageObject.height"
            :alt="item.imageObject.alt"
          />
        </a>
      </SwiperSlide>
    </Swiper>
    <a class="main-brands__link-desktop" href="/brands">Посмотреть все бренды</a>
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
