var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        staticClass: "main-page-vpt-override",
        attrs: { "vue-app-id": "main-page" }
      },
      "div",
      _vm.$props,
      false
    ),
    [_vm.inited ? _c("IndexPage") : _vm._e()],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }