var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-hits" }, [
    _c(
      "div",
      { staticClass: "product-hits__title" },
      [
        _c("ArrowLeft", {
          staticClass: "product-hits__button product-hits__button--prev"
        }),
        _vm._v(" "),
        _c("span", { staticClass: "product-hits__title-text" }, [
          _vm._v("Хиты продаж ")
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "product-hits__title-link showTapBarMenu",
            attrs: { href: "#" }
          },
          [_vm._v("Все товары")]
        ),
        _vm._v(" "),
        _c("ArrowRight", {
          staticClass: "product-hits__button product-hits__button--next"
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product-hits__block" },
      [
        _c(
          "Swiper",
          { ref: "swiper", attrs: { options: _vm.options } },
          _vm._l(_vm.items, function(item, index) {
            return _c("ProductCard", {
              key: "hits" + (index + 1),
              attrs: { item: item },
              on: { clickProduct: _vm.onClickProduct }
            })
          }),
          1
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "product-hits__pagination swiper-pagination__bullets"
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }