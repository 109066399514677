import { Vue, createClientApp } from 'src/apps/vue';
import App from './App';

export async function createApp(store, router, props = {}) {
  const app = new Vue({
    store,
    router,
    render: fn => fn(App, { props }),
  });

  return { app };
}

createClientApp('main-page', createApp);
