var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.banner.body
    ? _c(
        "a",
        { staticClass: "image-banner", attrs: { href: _vm.banner.cleanUrl } },
        [
          _c("img", {
            staticClass: "image-banner__img",
            attrs: { src: _vm.bannerImage, alt: _vm.banner.title }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }