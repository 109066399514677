var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "root", staticClass: "advantages__section" }, [
    _c("div", { staticClass: "advantages" }, [
      _c("a", { staticClass: "advantages__title", attrs: { href: _vm.href } }, [
        _vm._v("Наши преимущества")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "advantages__wrapper" },
        [
          _vm._l(_vm.specialOffers, function(item, i) {
            return [
              _vm.canShowElement(i)
                ? _c(
                    "a",
                    {
                      key: item.id,
                      staticClass: "advantages__item",
                      attrs: { href: item.cleanURL }
                    },
                    [
                      item.image
                        ? _c("img", {
                            staticClass: "advantages__img lazy",
                            attrs: {
                              "data-src": item.image.src,
                              width: item.image.width,
                              height: item.image.height,
                              alt: item.image.alt
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "advantages__text-wrapper" }, [
                        _c("span", { staticClass: "advantages__text" }, [
                          _vm._v(_vm._s(item.title))
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "advantages__text advantages__text--small"
                        })
                      ])
                    ]
                  )
                : _vm._e()
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "advantages__btn", on: { click: _vm.onToggle } },
        [
          _vm.preview
            ? [_vm._v("Мало преимуществ? Здесь ещё больше ▼")]
            : [_vm._v("Скрыть преимущества ▲")]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }