var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("swiper-slide", { staticClass: "product-hits__link" }, [
    _c("a", { attrs: { href: _vm.item.url + "/#reviews" } }, [
      _c("div", {
        staticClass: "mp-widget pointer",
        attrs: {
          "data-mp-widget-type": "SmallProductRating",
          "data-mp-show-stars": "true",
          "data-mp-show-rating-value": "true",
          "data-mp-show-reviews-count": "false",
          "data-mp-show-service-logo": "false",
          "data-mp-productSKUs": _vm.item.sku
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "swiper-slide product-hits__item",
        attrs: { href: _vm.item.url },
        on: {
          click: function($event) {
            return _vm.onClickProduct(_vm.item, _vm.$vnode.key)
          }
        }
      },
      [
        _c("div", { staticClass: "product-hits__image-container" }, [
          _c("img", {
            staticClass: "product-hits__image custom swiper-lazy",
            attrs: { "data-srcset": _vm.imageSrcSet }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product-hits__name" }, [
          _vm._v(_vm._s(_vm.item.name))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product-hits__price-wrapper" }, [
          _c("span", { staticClass: "product-hits__price" }, [
            _vm._v(_vm._s(_vm.item.formattedPrice))
          ]),
          _vm._v(" "),
          _vm.item.isSale && _vm.item.price < _vm.item.salePrice
            ? _c(
                "span",
                { staticClass: "product-hits__price product-hits__price--old" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.item.formattedSalePrice) +
                      "\n      "
                  )
                ]
              )
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }