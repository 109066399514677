<script>
import { mapGetters } from 'vuex';
import format from 'date-fns/format';

export default {
  data() {
    return {
      columnLimit: 3,
    };
  },

  computed: {
    ...mapGetters('main-page', ['news']),
  },

  methods: {
    formatDate(dateString) {
      return format(new Date(dateString), `dd.MM.yyyy`);
    },
  },
};
</script>

<template>
  <div class="main-news">
    <div class="main-news__title">
      <a class="main-news__link" href="/news/">Новости ОЧКАРИК</a>
      <a class="main-news__link main-news__link_mobile" href="/news/">Все новости</a>
    </div>

    <div class="main-news__list">
      <div v-for="message in news" :key="message.id" class="main-news__content">
        <div class="main-news__date">
          {{ formatDate(message.date) }}
        </div>

        <a class="main-news__link main-news__link_news" :href="message.cleanUrl">
          {{ message.title }}
        </a>
      </div>
    </div>
  </div>
</template>
