<script>
import SEO from 'src/apps/main-page/components/SEO';
import BlogNews from 'src/apps/main-page/components/BlogNews';
import Advantages from 'src/apps/main-page/components/Advantages';
import Catalog from 'src/apps/main-page/components/Catalog';

export default {
  components: {
    SEO,
    BlogNews,
    Advantages,
    Catalog,
  },
};
</script>

<template>
  <div class="main-page-wrapper">
    <Catalog class="main-page-wrapper__catalog" />
    <Advantages class="main-page-wrapper__advantages" />
    <BlogNews class="main-page-wrapper__blog-news" />
    <SEO class="main-page-wrapper__seo" />
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
