<script>
export default {
  name: 'ImageBanner',
  props: {
    banner: {
      type: Object,
      default: () => {},
    },
    screen: {
      type: String,
      default: 'desktop',
      validator(value) {
        return ['mobile', 'tablet', 'desktop'].includes(value);
      },
    },
    bannerImage: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <a v-if="banner.body" :href="banner.cleanUrl" class="image-banner">
    <img :src="bannerImage" class="image-banner__img" :alt="banner.title" />
  </a>
</template>

<style lang="scss" src="./style.scss"></style>
