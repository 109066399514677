<script>
import IndexPage from 'src/apps/main-page/pages/IndexPage';
import { mapGetters, mapActions } from 'vuex';
import store from 'src/apps/main-page/store';
import { ANALYTICS } from 'src/constants';
import { initMneniaPro } from 'src/features/widgets';

export default {
  components: {
    IndexPage,
  },

  computed: {
    ...mapGetters('main-page', ['inited']),
  },

  methods: {
    ...mapActions('main-page', ['init', 'clientInit']),

    triggerAnalytics() {
      window.xcore.trigger(ANALYTICS.MAIN_PAGE);
    },
  },

  async serverPrefetch() {
    await this.init();
  },

  created() {
    this.$store.registerModule('main-page', store, {
      preserveState: typeof window !== 'undefined',
    });
  },

  async mounted() {
    initMneniaPro();
    await this.clientInit();

    window.onloadAttach(() => {
      this.triggerAnalytics();
    });
  },

  destroyed() {
    this.$store.unregisterModule('main-page');
  },
};
</script>

<template>
  <div class="main-page-vpt-override" vue-app-id="main-page" v-bind="$props">
    <IndexPage v-if="inited" />
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
