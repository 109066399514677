var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.clientInited
    ? _c(
        "Swiper",
        {
          ref: "swiper",
          staticClass: "main-slider",
          attrs: { options: _vm.swiperOptions },
          on: {
            mouseover: _vm.onMouseOver,
            mouseout: _vm.onMouseOut,
            ready: _vm.handleSlideAnalytics,
            transitionEnd: _vm.handleSlideAnalytics
          }
        },
        [
          _vm._l(_vm.availablePromotions, function(item, i) {
            return _c(
              "SwiperSlide",
              {
                key: "main-slider-" + item.id,
                staticClass: "main-slider__slide",
                attrs: { "data-id": item.id, "data-position": i + 1 }
              },
              [
                _c(
                  item.needUrl ? "a" : "div",
                  {
                    tag: "a",
                    staticClass: "main-slider__link",
                    attrs: {
                      href: item.cleanUrl || "",
                      "data-name": item.title,
                      "data-info": item.hint
                    }
                  },
                  [
                    item.hint
                      ? _c(
                          "div",
                          {
                            class: [
                              "main-slider__hint",
                              {
                                "main-slider__hint_active": _vm.isTooltipActive(
                                  item
                                )
                              }
                            ],
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onToggleTooltip(item)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "main-slider__hint-button" },
                              [_vm._v("\n          ?\n        ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "main-slider__hint-text" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(item.hint) +
                                    "\n        "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.images.default
                      ? _c("img", {
                          staticClass: "main-slider__img swiper-lazy",
                          attrs: {
                            alt: item.title,
                            "data-srcset":
                              item.images.default.src +
                              " " +
                              item.images.default.width +
                              "w,\n           " +
                              item.images.medium.src +
                              " " +
                              item.images.medium.width +
                              "w,\n           " +
                              item.images.small.src +
                              " " +
                              item.images.small.width +
                              "w",
                            "data-src": item.images.default.src,
                            sizes:
                              "(max-width: 1279px) calc(100vw - 46px), 929px"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onClick(item, i + 1)
                            }
                          }
                        })
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "main-slider__button main-slider__button--prev",
              attrs: {
                slot: "button-prev",
                width: "22",
                height: "38",
                viewBox: "0 0 22 38",
                preserveAspectRatio: "xMinYMin meet",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              },
              slot: "button-prev"
            },
            [
              _c("path", {
                attrs: {
                  d: "M20 36L3 19L20 2",
                  stroke: "white",
                  "stroke-width": "3"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "main-slider__button main-slider__button--next",
              attrs: {
                slot: "button-next",
                width: "22",
                height: "38",
                viewBox: "0 0 22 38",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              },
              slot: "button-next"
            },
            [
              _c("path", {
                attrs: {
                  d: "M2 2L19 19L2 36",
                  stroke: "white",
                  "stroke-width": "3"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "main-slider__pagination swiper-pagination__bullets",
            attrs: { slot: "pagination" },
            slot: "pagination"
          })
        ],
        2
      )
    : _c("div", { staticClass: "main-slider__placeholder" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }