var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-page-wrapper" },
    [
      _c("Catalog", { staticClass: "main-page-wrapper__catalog" }),
      _vm._v(" "),
      _c("Advantages", { staticClass: "main-page-wrapper__advantages" }),
      _vm._v(" "),
      _c("BlogNews", { staticClass: "main-page-wrapper__blog-news" }),
      _vm._v(" "),
      _c("SEO", { staticClass: "main-page-wrapper__seo" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }