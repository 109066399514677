var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "seo-section" }, [
    _c("article", { staticClass: "seo-article" }, [
      _c("header", { staticClass: "seo-article__title" }, [
        _c("h1", [_vm._v(_vm._s(_vm.h1))])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["seo-article__content", { all: !_vm.preview }] },
        _vm._l(_vm.body, function(row, i) {
          return _c("div", {
            key: i,
            class: [
              "seo-article__content__text",
              "seo-article__content__text-columns"
            ],
            domProps: { innerHTML: _vm._s(row) }
          })
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "seo-article__more" }, [
        _c(
          "a",
          {
            staticClass: "seo-article__more__button",
            attrs: { id: "showall-seo" },
            on: { click: _vm.onClickPreviewText }
          },
          [_vm._v("\n        " + _vm._s(_vm.previewText) + "\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }