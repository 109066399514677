<script>
import ProductHits from 'src/apps/main-page/components/ProductHits';
import Brands from 'src/apps/main-page/components/Brands';
import NavCatalog from './NavCatalog';
import MobileNavCatalog from './MobileNavCatalog';
import PromoSlider from './PromoSlider';

export default {
  components: { NavCatalog, MobileNavCatalog, PromoSlider, ProductHits, Brands },
};
</script>

<template>
  <div>
    <div class="nav-catalog__wrapper">
      <NavCatalog />
      <PromoSlider />
    </div>

    <Brands />

    <ProductHits />

    <MobileNavCatalog />
  </div>
</template>
