var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tooltip__wrapper",
      class: { "tooltip__wrapper--inline": _vm.inline },
      on: { mouseover: _vm.mouseoverHandler }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.text
        ? _c(
            "div",
            {
              class: [
                { "tooltip--mobile-disable": _vm.disableOnMobile },
                "tooltip"
              ]
            },
            [
              _c("div", { staticClass: "tooltip__text" }, [
                _vm._v(_vm._s(_vm.text))
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }