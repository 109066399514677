<script>
import { mapGetters } from 'vuex';
import format from 'date-fns/format';
import { preview } from 'src/utils/cdn';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import LazyLoad from 'vanilla-lazyload';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  directives: {
    swiper: directive,
  },

  data() {
    return {
      lazyLoad: null,
      mainComponent: 'Swiper',
      swiperOptions: {
        watchSlidesVisibility: true,
        spaceBetween: 10,
        loop: true,
        centeredSlides: true,
        updateOnWindowResize: true,
        slidesPerView: 'auto',
        pagination: {
          el: '.blog__pagination',
          clickable: true,
        },
        breakpoints: {
          640: {
            centeredSlides: false,
          },
          1025: {
            centeredSlides: true,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters('main-page', ['blogPosts']),
  },

  methods: {
    formatDate(dateString) {
      return format(new Date(dateString), `dd.MM.yyyy`);
    },

    imageUrl(blog) {
      const img = blog.images.main?.preview || '';

      return preview(img, { width: 600 });
    },

    computeImageComponent(img) {
      if (img) {
        return 'img';
      }

      return 'div';
    },

    resizeHandlerSlider() {
      const widthWindow = window.innerWidth;

      if (widthWindow < 1280) {
        this.mainComponent = 'Swiper';
      } else {
        this.mainComponent = 'div';
      }

      this.$nextTick(() => this.lazyLoad?.update());
    },
  },

  mounted() {
    this.lazyLoad = new LazyLoad({ container: this.$refs.root });

    this.resizeHandlerSlider();
    window.addEventListener('resize', this.resizeHandlerSlider);
  },

  beforeDestroy() {
    this.lazyLoad?.destroy();
  },
};
</script>

<template>
  <div ref="root" class="blog">
    <a href="/blog/" class="blog__title blog--text blog--text_title">Наш блог</a>

    <component :is="mainComponent" :options="swiperOptions" class="blog__slider swiper-container">
      <figure v-for="blogItem in blogPosts" :key="blogItem.id" class="blog__item swiper-slide">
        <a :href="blogItem.cleanUrl" class="blog__link">
          <div class="blog__image-wrapper">
            <component
              :class="{ 'blog__image-fallback': !imageUrl(blogItem), lazy: true }"
              :is="computeImageComponent(imageUrl(blogItem))"
              :src="imageUrl(blogItem)"
              width="600"
              height="352"
            />
          </div>

          <figcaption class="blog__content">
            <p class="blog__item-title blog--text blog__last-override_title">{{ blogItem.title }}</p>
            <p class="blog__item-label blog--text blog__last-override_subtitle">{{ blogItem.category.title }}</p>
            <p v-html="blogItem.preview" class="blog__item-subtitle blog--text"></p>
          </figcaption>

          <footer class="blog__meta">
            <div class="blog__meta-item blog--text">
              <i class="blog__icon"></i>
              <span>{{ blogItem.views }}</span>
            </div>

            <div class="blog__meta-item blog--text">
              {{ formatDate(blogItem.createdAt) }}
            </div>
          </footer>
        </a>
      </figure>

      <a href="/blog/" class="blog__item blog__item--last blog--text swiper-slide ">
        <p class="blog__item-title blog__item-title--last blog--text blog__last-override_title">
          Больше статей
        </p>

        <p class="blog__item-subtitle blog__item-text--last blog--text blog__last-override_subtitle">
          Ещё больше интересных статей и обзоров на современные тенденции и моду в оптике у нас в Блоге
        </p>

        <p class="blog__item-link blog-text blog__last-override_read-articles">Читать статьи</p>
      </a>
    </component>

    <div class="blog__pagination"></div>
  </div>
</template>
